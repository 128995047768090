import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Icon, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наші послуги в Pixels Drift Autorents
			</title>
			<meta name={"description"} content={"Плавні поїздки, легке керування - Pixels Drift, ваша подорож починається тут"} />
			<meta property={"og:title"} content={"Наші послуги | Наші послуги в Pixels Drift Autorents"} />
			<meta property={"og:description"} content={"Плавні поїздки, легке керування - Pixels Drift, ваша подорож починається тут"} />
			<meta property={"og:image"} content={"https://pixelsdrift.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="p"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong sm-text-align="left">
						Оденда авто Pixels Drift
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Наші послуги
				</Text>
				<Text
					as="h2"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Плавні поїздки, легке керування - Pixels Drift, ваша подорож починається тут
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Міські автомобілі
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Компактні та економічні, ідеально підходять для того, щоб з легкістю пересуватися міськими вулицями.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Сімейні позашляховики
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Просторі та комфортні, ідеально підходять для сімейного відпочинку або групових поїздок, з великою кількістю місця для багажу.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Розкішні седани
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Для тих, хто шукає елегантності та високої продуктивності, наші розкішні седани пропонують досвід водіння преміум-класу.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Добова оренда
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Ідеально підходить для коротких поїздок або швидких подорожей, пропонуючи вам гнучкість в оренді автомобіля лише на один день.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Довгострокова оренда
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Для тривалої відпустки або ділових поїздок наші плани довгострокової оренди передбачають привабливі знижки та пільги.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Допомога на дорозі 24/7
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Душевний спокій під час подорожі з цілодобовою підтримкою в будь-яких непередбачуваних ситуаціях.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Навігаційні системи GPS
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Подорожуйте з упевненістю, використовуючи нашу новітню технологію GPS, яка гарантує, що ви ніколи не заблукаєте.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Дитячі крісла
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Сімейний прокат з високоякісними дитячими кріслами для безпеки та комфорту ваших малюків.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://pixelsdrift.com/img/4.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://pixelsdrift.com/img/5.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					Наша команда допоможе вам спланувати ідеальну подорож, починаючи з вибору правильного автомобіля і закінчуючи порадами щодо мальовничих маршрутів та місць, які варто відвідати.
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://pixelsdrift.com/img/6.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Вирушайте у свою наступну подорож з упевненістю, що Pixels Drift Autorent забезпечить вас усім необхідним. Від прогулянок містом до заміських пригод, ми запрошуємо вас створити свою історію на дорогах, якими ви ще не їздили, разом з нами. Наша прихильність до якості, зручності та персоналізованого обслуговування гарантує, що ваша наступна подорож буде настільки ж безтурботною, наскільки й незабутньою.

				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});